var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "interactive-record-container" }, [
    _c("div", { staticClass: "record-content" }, [
      _c("div", { staticClass: "date" }, [
        _vm._v(" " + _vm._s(_vm.recordInfo.date) + " "),
      ]),
      _c("div", { staticClass: "add-file-area" }, [
        _c("div", { staticClass: "avatar" }, [
          _c("img", { attrs: { src: _vm.recordInfo.avatar, alt: "" } }),
        ]),
        _c("div", { staticClass: "title" }, [
          _vm._v(
            " " +
              _vm._s(_vm.recordInfo.name) +
              " added a new " +
              _vm._s(_vm.fileTypeName) +
              " "
          ),
        ]),
      ]),
      _c("div", { staticClass: "file-area" }, [
        _c("div", { staticClass: "file-name" }, [
          _c("img", {
            attrs: {
              src: require("@/assets/img/19-Dashboard/paperclip.svg"),
              alt: "",
            },
          }),
          _c("span", { staticClass: "file-name" }, [
            _vm._v(_vm._s(_vm.recordInfo.fileName)),
          ]),
        ]),
        _c(
          "span",
          {
            staticClass: "button-area download",
            on: {
              click: function ($event) {
                return _vm.viewReviewFile(
                  _vm.recordInfo.fileSrc,
                  _vm.recordInfo.fileName
                )
              },
            },
          },
          [_vm._v(" DOWNLOAD ")]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }