var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "syllabus-container" }, [
    _vm.currentLessonIndex % 8 !== 7 && _vm.currentLessonIndex % 8 !== 0
      ? _c(
          "div",
          { staticClass: "syllabus-box" },
          _vm._l(_vm.syllabusList, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "syllabus-item",
                class: {
                  noDisplay:
                    index !== 0 &&
                    [1, 3, 5].indexOf(_vm.currentLessonIndex) > -1,
                },
              },
              [
                _c("div", { staticClass: "title-area" }, [
                  _c("span", { staticClass: "title" }, [
                    _vm._v(" " + _vm._s(item.title) + " "),
                  ]),
                ]),
                _c("div", { staticClass: "syllabus-content" }, [
                  _c("div", { staticClass: "tips" }, [
                    _vm._v(" " + _vm._s(item.tips) + " "),
                  ]),
                  _c(
                    "div",
                    { staticClass: "words-area" },
                    _vm._l(item.wordsList, function (ele, indexSon) {
                      return _c(
                        "div",
                        { key: indexSon + "son", staticClass: "word-item" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "syllabus-pinyin pinyin font-pinyin-medium",
                            },
                            [_vm._v(" " + _vm._s(ele.pinyin) + " ")]
                          ),
                          ele.hanzi
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "syllabus-hanzi font-hanzi-medium",
                                },
                                [_vm._v(" " + _vm._s(ele.hanzi) + " ")]
                              )
                            : _vm._e(),
                          ele.strokeImg
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "syllabus-hanzi font-hanzi-medium",
                                },
                                [
                                  _c("img", {
                                    attrs: { src: ele.strokeImg, alt: "" },
                                  }),
                                ]
                              )
                            : _vm._e(),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
              ]
            )
          }),
          0
        )
      : _vm._e(),
    _vm.currentLessonIndex % 8 === 7
      ? _c("div", { staticClass: "syllabus-box lesson-7" }, [
          _c("div", { staticClass: "item-area" }, [
            _vm._m(0),
            _c("div", { staticClass: "syllabus-content" }, [
              _c(
                "div",
                { staticClass: "words-area" },
                _vm._l(
                  _vm.syllabusInfo.syllabus.knowledge,
                  function (ele, indexSon) {
                    return _c(
                      "div",
                      { key: indexSon + "son", staticClass: "word-item" },
                      [_c("div", {}, [_vm._v(" " + _vm._s(ele.content) + " ")])]
                    )
                  }
                ),
                0
              ),
              _c("div", { staticClass: "pronunciation font-hanzi-medium" }, [
                _vm._v(
                  " " + _vm._s(_vm.syllabusInfo.syllabus.pronunciation) + " "
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "item-area" }, [
            _vm._m(1),
            _c("div", { staticClass: "syllabus-content" }, [
              _c(
                "div",
                { staticClass: "words-area" },
                _vm._l(
                  _vm.syllabusInfo.syllabus.skills,
                  function (ele, indexSon) {
                    return _c(
                      "div",
                      { key: indexSon + "son", staticClass: "word-item" },
                      [_c("div", {}, [_vm._v(" " + _vm._s(ele.content) + " ")])]
                    )
                  }
                ),
                0
              ),
            ]),
          ]),
        ])
      : _vm._e(),
    _vm.currentLessonIndex % 8 === 0
      ? _c(
          "div",
          { staticClass: "syllabus-box lesson-8" },
          [
            _c("div", { staticClass: "item-area" }, [
              _vm._m(2),
              _c("div", { staticClass: "syllabus-content" }, [
                _c("div", { staticClass: "welcome" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.syllabusInfo.syllabus.knowledge.welcome) +
                      " "
                  ),
                ]),
                _c("div", { staticClass: "welcome" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.syllabusInfo.syllabus.knowledge.content) +
                      " "
                  ),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "intrudction" }, [
              _vm._v("In this lesson, you will learn to:"),
            ]),
            _vm._l(_vm.syllabusInfo.syllabus.skills, function (ele, indexSon) {
              return _c(
                "div",
                { key: indexSon + "son", staticClass: "item-area" },
                [
                  _c("div", { staticClass: "title-area" }, [
                    _c("span", { staticClass: "title" }, [
                      _vm._v(" " + _vm._s(ele.title) + " "),
                    ]),
                  ]),
                  _c("div", { staticClass: "syllabus-content" }, [
                    _c("div", { staticClass: "words-area" }, [
                      _c("div", { staticClass: "word-item" }, [
                        _c("div", {}, [
                          _vm._v(" " + _vm._s(ele.content) + " "),
                        ]),
                      ]),
                    ]),
                  ]),
                ]
              )
            }),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title-area" }, [
      _c("span", { staticClass: "title" }, [_vm._v(" Knowledge ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title-area" }, [
      _c("span", { staticClass: "title" }, [_vm._v(" Skills ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title-area" }, [
      _c("span", { staticClass: "title" }, [_vm._v(" Knowledge ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }