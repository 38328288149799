<template>
  <div class="sing-page-container">
    <template
        v-for="(item, index) in componentsList"
      >
          <transition name="slide-fade" :key="index">
            <component :key="index" :is="item" :isReviewPage="true" v-if="lessonNo === 2 * index + 1">
            </component>
          </transition>
      </template>
  </div>
</template>
<script>
const Lesson1 = ()=>import("@/views/Course/Level1/Chapter1/Lesson1/18_SingPage")
const Lesson3 = ()=>import("@/views/Course/Level1/Chapter1/Lesson3/17_SingPage")
const Lesson5 = ()=>import("@/views/Course/Level1/Chapter1/Lesson5/18_SingPage")
const Lesson7 = ()=>import("@/views/Course/Level1/Chapter1/Lesson7/4_OpeningSongPage")

const Lesson9 = ()=>import("@/views/Course/Level1/Chapter2/Lesson9/17_SingPage")
const Lesson11 = ()=>import("@/views/Course/Level1/Chapter2/Lesson11/17_SingPage")
const Lesson13 = ()=>import("@/views/Course/Level1/Chapter2/Lesson13/18_SingPage")
const Lesson15 = ()=>import("@/views/Course/Level1/Chapter2/Lesson15/4_OpeningSongPage")

const Lesson17 = ()=>import("@/views/Course/Level1/Chapter3/Lesson17/18_SingPage")
const Lesson19 = ()=>import("@/views/Course/Level1/Chapter3/Lesson19/22_SingPage")
const Lesson21 = ()=>import("@/views/Course/Level1/Chapter3/Lesson21/17_SingPage")
const Lesson23 = ()=>import("@/views/Course/Level1/Chapter3/Lesson23/4_OpeningSongPage")

const Lesson25 = ()=>import("@/views/Course/Level1/Chapter4/Lesson25/17_SingPage")
const Lesson27 = ()=>import("@/views/Course/Level1/Chapter4/Lesson27/21_SingPage")
const Lesson29 = ()=>import("@/views/Course/Level1/Chapter4/Lesson29/17_SingPage")
const Lesson31 = ()=>import("@/views/Course/Level1/Chapter4/Lesson31/4_OpeningSongPage")

const Lesson33 = ()=>import("@/views/Course/Level1/Chapter5/Lesson33/17_SingPage")
const Lesson35 = ()=>import("@/views/Course/Level1/Chapter5/Lesson35/19_SingPage")
const Lesson37 = ()=>import("@/views/Course/Level1/Chapter5/Lesson37/18_SingPage")
const Lesson39 = ()=>import("@/views/Course/Level1/Chapter5/Lesson39/4_OpeningSongPage")

const Lesson41 = ()=>import("@/views/Course/Level1/Chapter6/Lesson41/19_SingPage")
const Lesson43 = ()=>import("@/views/Course/Level1/Chapter6/Lesson43/17_SingPage")
const Lesson45 = ()=>import("@/views/Course/Level1/Chapter6/Lesson45/18_SingPage")
const Lesson47 = ()=>import("@/views/Course/Level1/Chapter6/Lesson47/4_OpeningSongPage")

const Lesson49 = ()=>import("@/views/Course/Level1/Chapter7/Lesson49/19_SingPage")
const Lesson51 = ()=>import("@/views/Course/Level1/Chapter7/Lesson51/17_SingPage")
const Lesson53 = ()=>import("@/views/Course/Level1/Chapter7/Lesson53/18_SingPage")
const Lesson55 = ()=>import("@/views/Course/Level1/Chapter7/Lesson55/4_OpeningSongPage")

const Lesson57 = ()=>import("@/views/Course/Level1/Chapter8/Lesson57/18_SingPage")
const Lesson59 = ()=>import("@/views/Course/Level1/Chapter8/Lesson59/19_SingPage")
const Lesson61 = ()=>import("@/views/Course/Level1/Chapter8/Lesson61/18_SingPage")
const Lesson63 = ()=>import("@/views/Course/Level1/Chapter8/Lesson63/4_OpeningSongPage")
export default {
  props: {
    lessonNo: {
      type: Number,
    },
  },
  components: {
    Lesson1,
    Lesson3,
    Lesson5,
    Lesson7,
    Lesson9,
    Lesson11,
    Lesson13,
    Lesson15,
    Lesson17,
    Lesson19,
    Lesson21,
    Lesson23,
    Lesson25,
    Lesson27,
    Lesson29,
    Lesson31,
    Lesson33,
    Lesson35,
    Lesson37,
    Lesson39,
    Lesson41,
    Lesson43,
    Lesson45,
    Lesson47,
    Lesson49,
    Lesson51,
    Lesson53,
    Lesson55,
    Lesson57,
    Lesson59,
    Lesson61,
    Lesson63,
  },
  data() {
    return {
      componentsList:[
            Lesson1,
    Lesson3,
    Lesson5,
    Lesson7,
    Lesson9,
    Lesson11,
    Lesson13,
    Lesson15,
    Lesson17,
    Lesson19,
    Lesson21,
    Lesson23,
    Lesson25,
    Lesson27,
    Lesson29,
    Lesson31,
    Lesson33,
    Lesson35,
    Lesson37,
    Lesson39,
    Lesson41,
    Lesson43,
    Lesson45,
    Lesson47,
    Lesson49,
    Lesson51,
    Lesson53,
    Lesson55,
    Lesson57,
    Lesson59,
    Lesson61,
    Lesson63,
      ]
    }
  }
}; 
</script>
<style scoped lang="scss">
.sing-page-container {
  width: calc(100% - 20px);
  // height: calc(100% - 20px);
  padding: 10px;
}
</style>
