var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "sing-page-container" },
    [
      _vm._l(_vm.componentsList, function (item, index) {
        return [
          _c(
            "transition",
            { key: index, attrs: { name: "slide-fade" } },
            [
              _vm.lessonNo === 2 * index + 1
                ? _c(item, {
                    key: index,
                    tag: "component",
                    attrs: { isReviewPage: true },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }